import React, { useEffect, useState } from "react";
import "../../App.css";
import MainLayout from "../../Layouts/MainLayout";
import {
  Table,
  Icon,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  TextArea,
  ModalFooter,
  Select,
} from "design-react-kit";

import { useParams, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import listAPI from "../../Apis/EnumAPI";
import { GenericAPI } from "../../Apis/GenericAPI";
import dayjs from "dayjs";

export default function IndexServiceRequestPayment(props) {
  let params = useParams();

  const navigate = useNavigate();
  const [requests, setRequests] = useState(null);
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [value, setValue] = useState(null);
  const [valueMethod, setValueMethod] = useState(null);
  const [saved, setSaved] = useState({
    id: "",
    status: "",
    message: "",
    service_id: "",
  });
  const [search, setSearch] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [id, setId] = useState("");
  const [practice_n, setPractice_n] = useState("");
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");
  const [data, setData] = useState("");
  const [date, setDate] = useState("");
  const [paymentCode, setPaymentCode] = useState("");
  const [serviceNameLink, setServiceNameLink] = useState("");
  const [request, setPaymentRequest] = useState("");
  const [iuv, setIuv] = useState("");
  const [searchData, setSearchData] = useState({});
  //Modifica
  const [open, toggleModal] = useState(false);
  const toggle = () => toggleModal(!open);
  const openModal = (id, date, paymentCode) => {
    setId(id);
    setDate(date);
    setPaymentCode(paymentCode);
    toggleModal(true);
  };
  //dettagli
  const [open2, toggleModal2] = useState(false);
  const toggle2 = () => toggleModal2(!open2);
  const openModal2 = (
    id,
    paymentCode,
    serviceNameLink,
    date,
    amount,
    title,
    paymentMethod,
    request,
    iuv
  ) => {
    setId(id);
    setPaymentCode(paymentCode);
    setServiceNameLink(serviceNameLink);
    setDate(date);
    setAmount(amount);
    setTitle(title);
    setPaymentMethod(paymentMethod);
    setPaymentRequest(request);
    setIuv(iuv);
    toggleModal2(true);
  };

  const defaultOptions = [
    { value: "IN_PROGRESS", label: "in corso" },
    { value: "TO_COMPLETE", label: "da completare" },
    { value: "DRAFT", label: "in bozza" },
    { value: "WAITING", label: "in attesa" },
    { value: "ERROR", label: "rifiutata" },
    { value: "ACCEPTED", label: "accolta" },
    { value: "COMPLETED", label: "conclusa" },
  ];
  const defaultServices = [
    { value: "SPA", label: "Prenotazione appuntamento" },
    { value: "SRA", label: "Richiesta di assistenza" },
    { value: "SSD", label: "Segnalazione disservizio" },
    { value: "RMS", label: "Richiesta mensa scolastica" },
    { value: "PTI", label: "Pagamento IMU - F24" },
    { value: "RTS", label: "Richiesta trasporto scolastico" },
    { value: "PSP", label: "Occupazione suolo pubblico" },
    { value: "PPC", label: "Pagamento passo carrabile" },
    { value: "PCO", label: "Pagamento contravvenzioni" },
    { value: "RAAT", label: "Richiesta accesso agli atti" },
    { value: "DBE", label: "Presentazione domanda per bonus economici" },
  ];
  const defaultPayment = [
    { value: "Pagato", label: "Pagato" },
    { value: "Non pagato", label: "Non pagato" },
  ];
  const defaultPaymentMethod = [
    { value: "Bonifico", label: "Bonifico" },
    { value: "PagoPa", label: "PagoPa" },
    { value: "F24", label: "F24" },
    { value: "Conto Corrente", label: "Conto Corrente" },
  ];

  useEffect(() => {
    //inizioalize first view first step
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
      console.log(user);
    }
    getRequest();
  }, []);

  const handleChange = (e) => {
    setValue(e.target.options[e.target.selectedIndex].value);
  };

  const handleChangeMethod = (e) => {
    setValueMethod(e.target.options[e.target.selectedIndex].value);
  };

  const getRequest = async () => {
    try {
      const response = await GenericAPI.get(
        listAPI.ListServiceRequestPayment,
        params.service_id
      );
      setRequests(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  //ho creato questa funzione per recuperarmi la label in italiano, modifica pure se non va bene, è funzionante
  function findLabelByValue(value) {
    const option = defaultServices.find((option) => option.value === value);
    return option ? option.label : "";
  }
  function findStatusByValue(value) {
    const option = defaultPayment.find((option) => option.value === value);
    return option ? option.label : "";
  }

  const save = async () => {
    try {
      //const selectedLabel = findLabelByValue(value);
      //messageSaving()
      //console.log(selectedLabel);
      await GenericAPI.save_payment({
        status: value,
        id: id,
        payment_method: valueMethod,
        service_id: params.service_id,
      });
      toggle();
      getRequest();
      //window.location.reload(true)
    } catch (err) {
      console.log(err);
    }
  };

  const messageSaving = (e) => {
    //console.log("ciao " + e.target.value);
    setSaved((prev) => {
      return {
        ...prev,
        message: e.target.value,
        id: e.target.getAttribute("data-protocollo"),
      };
    });
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const onDownload = async (id, practice_number) => {
    try {
      GenericAPI.download(listAPI.DownloadAttach, id).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/zip" })
        );

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", practice_number + "_allegati.zip");
        document.body.appendChild(link);
        link.click();
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onSearch = async (e) => {
    try {
      const res = await GenericAPI.search_payment(params.service_id, search);
      //console.log(res);
      console.log(res.data);
      setRequests(res.data);
      setSearchData(res.data);
      //console.log(data);
      //usa setSearchData() per salvarci quello che ti ritorna l'api search
      //dsadsa
    } catch (err) {
      console.log(err);
    }
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = (e) => {
    e.preventDefault();

    // Headers for each column
    let headers = ["Id,Protocollo,Data,Stato,Importo,Descrizione,Metodo"];
    //let practice_number = requests?.practice_number
    // Convert users data to a csv
    const usersCsv = requests?.reduce((acc, user) => {
      const {
        id,
        request,
        date,
        state,
        amount,
        serviceNameLink,
        paymentMethod,
      } = user;
      acc.push(
        [
          id,
          request?.practice_number,
          date,
          state,
          amount + " €",
          serviceNameLink,
          paymentMethod,
        ].join(",")
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: "Pagamenti-" + findLabelByValue(params.service_id) + ".csv",
      fileType: "text/csv",
    });
  };

  const indietro = () => {
    navigate(-1);
  };

  return (
    <MainLayout auth={user} errors={props.errors} header={header}>
      <Container className="my-5">
        <Button
          style={{
            marginBottom: "50px",
            textAlign: "center",
            whiteSpace: "nowrap",
          }}
          onClick={indietro}
          tabIndex="-1"
          color="primary"
          size="xs"
          icon
        >
          <Icon icon="it-arrow-left" color="white" />
          Torna alla lista dei servizi
        </Button>
        <h2 className="fw-normal mb-5">
          Pagamenti - {findLabelByValue(params.service_id)}
        </h2>
        <div
          style={{ marginTop: "20px", marginBottom: "20px", display: "flex" }}
        >
          <input
            type="text"
            id="searchBar"
            className="search"
            placeholder={"Ricerca per numero di pratica"}
            onChange={onChangeSearch}
          />
          <input
            className="search-btn"
            type="button"
            value="&#9740;"
            onClick={onSearch}
          />
          <Button
            style={{
              textAlign: "center",
              whiteSpace: "nowrap",
            }}
            onClick={exportToCsv}
            tabIndex="-1"
            color="primary"
            size="xs"
            icon
          >
            <Icon icon="it-download" color="white" />
            Scarica CSV
          </Button>
        </div>

        <main>
          {requests == null ? (
            <Spinner active label />
          ) : (
            <div
              style={{ maxHeight: "800px", overflow: "auto" }}
              className="py-12"
            >
              <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                  <div className="p-6 bg-white border-b border-gray-200">
                    <div className="flex items-center justify-between mb-6"></div>

                    <Table striped>
                      <thead>
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">N° pratica</th>

                          <th scope="col">Data pagamento</th>
                          <th scope="col">Stato</th>
                          {(params.service_id === "RMS") |
                          (params.service_id === "RTS") |
                          (params.service_id === "PTI") |
                          (params.service_id === "PCO") |
                          (params.service_id === "PSP") |
                          (params.service_id === "PPC") |
                          (params.service_id === "RAAT") ? (
                            //posso usare anche <React.Fragment>... </React.Fragment>
                            <>
                              <th scope="col">Tipo pagamento</th>
                              <th scope="col">Modifica</th>
                              <th scope="col">Dettagli</th>
                            </>
                          ) : (
                            ""
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {requests?.map(
                          ({
                            id,
                            message,
                            date,
                            data,
                            amount,
                            pay,
                            state,
                            paymentMethod,
                            title,
                            serviceNameLink,
                            created_at,
                            paymentCode,
                            practice_number,
                            request,
                            iuv,
                          }) => (
                            <tr key={Math.random() * 31 * Math.random() * 11}>
                              <th scope="row">{id}</th>
                              <td>{request?.practice_number} </td>
                              <td>{date}</td>
                              <td>{state}</td>
                              {(params.service_id === "RMS") |
                              (params.service_id === "RTS") |
                              (params.service_id === "PTI") |
                              (params.service_id === "PCO") |
                              (params.service_id === "PSP") |
                              (params.service_id === "PPC") |
                              (params.service_id === "RAAT") ? (
                                <>
                                  <td>
                                    {params.service_id === "PTI"
                                      ? "F24"
                                      : paymentMethod}
                                  </td>
                                  <td>
                                    <Button
                                      onClick={() =>
                                        //TODO mostrare i messaggi come chiesto
                                        openModal(id, date, paymentCode)
                                      }
                                      id={id}
                                      tabIndex="-1"
                                      color="primary"
                                      size="xs"
                                      icon
                                    >
                                      <Icon icon="it-pencil" color="white" />
                                    </Button>
                                  </td>
                                  <td>
                                    <Button
                                      onClick={() =>
                                        //TODO mostrare i messaggi come chiesto
                                        openModal2(
                                          id,
                                          paymentCode,
                                          serviceNameLink,
                                          date,
                                          amount,
                                          title,
                                          paymentMethod,
                                          request,
                                          iuv
                                        )
                                      }
                                      id={id}
                                      tabIndex="-1"
                                      color="primary"
                                      size="xs"
                                      icon
                                    >
                                      <Icon
                                        icon="it-info-circle"
                                        color="white"
                                      />
                                    </Button>
                                  </td>
                                </>
                              ) : (
                                ""
                              )}
                            </tr>
                          )
                        )}
                        {requests?.length === 0 && (
                          <tr>
                            <td colSpan="6">No request found.</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      </Container>
      {/* modifica */}
      <Modal fade centered isOpen={open} toggle={toggle} labelledBy="esempio14">
        <ModalHeader toggle={toggle} id="esempio14">
          MODIFICA , PAGAMENTO N°: {paymentCode}
        </ModalHeader>
        <ModalBody>
          <form>
            <FormGroup>
              <select
                style={{
                  width: "100%",
                  border: "none",
                  borderBottom: "solid 1px darkgrey",
                }}
                id="status"
                onChange={handleChange}
              >
                <option selected disabled>
                  Scegli il nuovo stato
                </option>
                <option value={defaultPayment[0].value}>
                  {defaultPayment[0].label}
                </option>
                <option value={defaultPayment[1].value}>
                  {defaultPayment[1].label}
                </option>
              </select>
            </FormGroup>
            <FormGroup>
              <select
                style={{
                  width: "100%",
                  border: "none",
                  borderBottom: "solid 1px darkgrey",
                }}
                id="method"
                onChange={handleChangeMethod}
              >
                <option selected disabled>
                  Scegli il metodo di pagamento
                </option>
                <option value={defaultPaymentMethod[0].value}>
                  {defaultPaymentMethod[0].label}
                </option>
                <option value={defaultPaymentMethod[1].value}>
                  {defaultPaymentMethod[1].label}
                </option>
                <option value={defaultPaymentMethod[2].value}>
                  {defaultPaymentMethod[2].label}
                </option>
                <option value={defaultPaymentMethod[3].value}>
                  {defaultPaymentMethod[3].label}
                </option>
              </select>
            </FormGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Chiudi
          </Button>
          <Button color="primary" onClick={save}>
            Salva modifiche
          </Button>
        </ModalFooter>
      </Modal>
      {/* dettagli id,paymentCode,serviceNameLink,date,amount,title,paymentMethod*/}
      <Modal
        fade
        centered
        isOpen={open2}
        toggle={toggle2}
        labelledBy="esempio14"
      >
        <ModalHeader toggle={toggle2} id="esempio14">
          DETTAGLI , PAGAMENTO N°: {paymentCode}
        </ModalHeader>
        <ModalBody>
          <form>
            <FormGroup>
              {request?.practice_number && (
                <tr>Numero pratica: {request?.practice_number}</tr>
              )}
              {serviceNameLink && <tr>Servizio: {serviceNameLink}</tr>}
              {date && <tr>Data: {date}</tr>}
              {amount && <tr>Importo: {amount + " €"}</tr>}
              {title && request?.data?.multa == null && (
                <tr>Titolo: {title}</tr>
              )}
              {paymentMethod && <tr>Metodo: {paymentMethod}</tr>}
              {iuv && <tr>IUV: {iuv}</tr>}
              {request?.data?.multa ? (
                <>
                  {request?.data?.multa.intestatario && (
                    <tr>Intestatario: {request?.data?.multa.intestatario}</tr>
                  )}
                  {request?.data?.multa.codice_fiscale && (
                    <tr>
                      Codice Fiscale: {request?.data?.multa.codice_fiscale}
                    </tr>
                  )}
                  {request?.data?.multa.email && (
                    <tr>Email: {request?.data?.multa.email}</tr>
                  )}
                  {request?.data?.multa.telefono && (
                    <tr>Telefono: {request?.data?.multa.telefono}</tr>
                  )}
                  {request.data.multa.causale && (
                    <tr>Causale: {request.data.multa.causale}</tr>
                  )}
                  {request.data.multa.scadenzaPagamento && (
                    <tr>
                      Scadenza Pagamento: {request.data.multa.scadenzaPagamento}
                    </tr>
                  )}
                  {request.data.multa.targa && (
                    <tr>Targa: {request.data.multa.targa}</tr>
                  )}
                </>
              ) : (
                request?.data?.fatture && (
                  <>
                    {request?.data?.fatture.intestatario && (
                      <tr>
                        Intestatario: {request?.data?.fatture.intestatario}
                      </tr>
                    )}
                    {request?.data?.fatture.codice_fiscale && (
                      <tr>
                        Codice Fiscale: {request?.data?.fatture.codice_fiscale}
                      </tr>
                    )}
                    {request?.data?.fatture.email && (
                      <tr>Email: {request?.data?.fatture.email}</tr>
                    )}
                    {request?.data?.fatture.telefono && (
                      <tr>Telefono: {request?.data?.fatture.telefono}</tr>
                    )}
                    {request.data.fatture.causale && (
                      <tr>Causale: {request.data.fatture.causale}</tr>
                    )}
                    {request.data.fatture.scadenzaPagamento && (
                      <tr>
                        Scadenza Pagamento:{" "}
                        {request.data.fatture.scadenzaPagamento}
                      </tr>
                    )}
                    {request.data.fatture.targa && (
                      <tr>Targa: {request.data.fatture.targa}</tr>
                    )}
                  </>
                )
              )}
            </FormGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle2}>
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>
    </MainLayout>
  );
}
